import WebFont from "webfontloader"
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
import axios from "axios"

export const fetchDataRedux = (url, data, actionType, loader, setLoader, isShort) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (isShort) {
                    arr.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                dispatch({ type: actionType, payload: arr })
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            console.log(err.msg)
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        })
}

export const onFetchById = (url, data, actionType, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: actionType, payload: res.data[0] })
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            console.log(err.msg)
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        })
}

export const onDeleteData = (url, data, actionType, setSweet) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: actionType, payload: data.id })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}






export const fetchData = (url, data, setState, loader, setLoader, limit, isShort) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (limit) {
                    arr = arr.slice(0, limit)
                }
                if (isShort === "shortDataCt") {
                    arr.sort((a, b) => {
                        if (a.country_name > b.country_name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                if (isShort === "shortDataLg") {
                    arr.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                setState(arr)
            }
            else {
                setState([])
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
            console.log(err)
        })
}


export const fetchDataPrimereact = (url, data, setState, loader, setLoader, sortByName, singleData, dataVal, addAll) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (sortByName) {
                    arr.sort((a, b) => {
                        if (a[sortByName] > b[sortByName]) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                if (singleData) {
                    arr = arr.map((curElem) => {
                        return curElem[dataVal]
                    })
                    if (addAll) {
                        arr.unshift('All')
                    }
                } else {
                    if (addAll) {
                        arr.unshift({ name: 'All' })
                    }
                }

                setState(arr)
            }
            else {
                setState([])
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
            console.log(err)
        })
}

export const onUploadMedia = (data, fetchMedia, loader, setLoader, setPercent, type, handleShow) => (dispatch, getState) => {
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            'Authorization': getState().auth.token
        },
    }
    if (handleShow) {
        setLoader({
            ...loader,
            record: true
        })
    } else {
        setLoader({
            ...loader,
            upload: true
        })
    }

    commonAxios("upload-file", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (type) {
                    fetchMedia(res.data.path, type, res.data.thumbnail, res.data.localPath)
                    if (handleShow) {
                        handleShow()
                    }
                } else {
                    fetchMedia()
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false
            })
            console.log(err)
        })
}

export const onDeleteCampaign = (url, data, videos, setVideos, setSweet, fetchFuncn) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (fetchFuncn) {
                    fetchFuncn()
                } else {
                    setVideos(videos.filter((curElem) => {
                        return +curElem.id !== +data.id
                    }))
                }
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}

export const onUploadMultipleFiles = (data, handleFunc, loader, setLoader, setPercent, setUploadStatus) => (dispatch, getState) => {

    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            'Authorization': getState().auth.token
        },
    }

    commonAxios("upload-files", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                handleFunc(res.data)
                if (setUploadStatus) {
                    setUploadStatus("Completed")
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                upload: false
            })
        })
}

export const onFetchFont = (setFonts, toLoad, doboth) => (dispatch) => {
    const fontNotSet = ["Helvetica", "Bebas", "palatino linotype", "Subjectivity", "LEMON MILK", "Gill Sans Ultra Bold", "Gilroy", "MS Reference Sans Serif", "MS Serif"
        , "Microsoft JhengHei", "Times New Roman", "MS UI Gothic", "Bookman Old Style", "Franklin Gothic Book", "Gill Sans MT", "Eras Light ITC", "Microsoft Sans Serif", "Microsoft YaHei UI", "Helvetica Now Display", "Gadugi", "Tw Cen MT"
        , "Ebrima", "Subjectivity Serif", "Clarendon", "Segoe UI Emoji", "Fashion Fetish Heavy", "Bastian Sidney", "Palatino Linotype", "Integral CF", "Steelfish", "Monument Extended", "Metropolis", "SignPainter HouseScript", "ChunkFive", "Franklin Gothic", "Agency FB", "Marchelina Script", "Franklin Gothic Medium", "Nexa Bold", "Spartwell", "Spartwell"
        , "Akira Expanded", "Calibri", "Zeyada", "Droid Serif", "Droid Sans Mono", "Droid Sans"]

    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-fonts',
        data: {},
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            let arr = res.data.data.filter(curElem => {
                return !fontNotSet.includes(curElem.value)
            }).map((item) => {
                return (
                    item.value
                )
            })
            arr = arr.concat('Inter')

            arr = arr.sort((a, b) => {
                return a > b ? 1 : -1
            })
            if (doboth) {
                WebFont.load({
                    google: {
                        families: arr
                    }
                })
            }
            if (toLoad) {
                WebFont.load({
                    google: {
                        families: arr
                    }
                })
            } else {
                setFonts(arr);
            }
        }
    }).catch(err => {
        console.log(err)
    })
}

export const onViewMessageForPdf = (data, fName, html2pdf, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-messages", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arrData = res.data.reverse()
                const formatText = (text) => {
                    const regex = /(.{70})/g;
                    let data = text.replaceAll(/</g, '&lt;').replaceAll(/>/g, '&gt;');
                    data = data.replaceAll(regex, '$1<br>')
                    data = data.replaceAll("&lt;xmp&gt;", "").replaceAll("&lt;/xmp&gt;", "")
                    return data
                };
                const element = document.createElement('div');
                element.style.fontFamily = 'Arial';
                element.style.border = "2px solid black"
                element.style.fontSize = '15px';
                element.style.color = 'black';
                element.style.padding = "10px"
                element.style.minHeight = "169vh"
                element.style.whiteSpace = 'pre-wrap';
                let htmlArray = [];
                for (let i = 0; i < arrData.length; i++) {
                    const obj = arrData[i];
                    let message = obj.message;
                    if (message.includes("```html")) {
                        message = message.replaceAll("```html", ``)
                        message = message.replaceAll("```", "")
                    }
                    const type = obj.type
                    let htmlString = `<p style="color:black;padding-bottom:5px;text-align:justify;"><b>${type === "user" ? getState().auth.user.name : obj.staff[0].name}:-</b></p><pre style="color:black;line-height: 250%">${formatText(message)}</pre><br />`
                    htmlArray.push(htmlString);
                }

                htmlArray.push(`<p style="text-align:right;z-index:1;position:absolute;bottom:10px;right:10px"><b style="color:black;">This PDF is generated by AIOffices.</b></p>`);
                element.innerHTML = htmlArray.join('');

                const options = {
                    margin: 10,
                    filename: fName,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                };
                html2pdf().set(options).from(element).save().then((e) => {
                    setLoader({
                        ...loader,
                        download: false
                    })
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({
                    ...loader,
                    download: false
                })
            }

        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                download: false
            })
        })
}