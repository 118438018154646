import React, { useEffect, useState } from "react";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import { AiFillPlusCircle } from "react-icons/ai"
import { BiSearch } from "react-icons/bi"
import Select from 'react-select'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useDispatch, useSelector } from "react-redux";
import { fetchData, fetchDataRedux } from "../../../../Redux/Actions/CommonActions";
import CreateConvModal from "./CreateConvModal";
import { FaSpinner } from "react-icons/fa";
import TitleBar from "../../../CommonComponents/TitleBar";
import SingleConv from "./SingleConv";
import CreateProjConvModal from "./CreateProjConvModal";
import { Tooltip } from "react-tooltip";

const ChatNew = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const staffList = useSelector(state => state.staff.list)
    const activeCompany = useSelector(state => state.company.activeCompany)
    const [staffs, setstaffs] = useState([])
    const [proj, setProj] = useState([])
    const [isActive, setIsActive] = useState({
        staff: false,
    })
    const [conv, setConv] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [filterObj, setFilterObj] = useState({
        qs: "",
        qp: "",
        qe: "",
        typeS: "",
        typeP: "",
        typeE: ""
    })

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleChange = (name, value) => {
        setFilterObj({
            ...filterObj,
            [name]: value
        })
    }

    const staffConv = conv.filter((curElem) => {
        return +curElem.embedId === 0
    }).filter((curElem) => {
        return curElem.type === "single"
    })
    const projConv = conv.filter((curElem) => {
        return +curElem.embedId === 0
    }).filter((curElem) => {
        return curElem.type === "group"
    })

    const embedConv = conv.filter((curElem) => {
        return +curElem.embedId !== 0
    })

    useEffect(() => {
        if (staffList.length > 0) {
            setstaffs([{}, ...staffList].map((curElem, index) => {
                if (index === 0) {
                    return { value: '', label: 'All' }
                }
                return {
                    label: curElem.name,
                    value: curElem.name
                }
            }))

            let val = staffList.find(({ cid }) => +cid === +activeCompany.id)
            if (val) {
                setIsActive({
                    ...isActive,
                    staff: true
                })
            }
        }
    }, [staffList])

    const fetchConv = () => {
        let obj = { cid: activeCompany.id }
        dispatch(fetchData("fetch-conversation-all", obj, setConv, loader, setLoader))
    }

    const fetchStaffs = () => {
        let obj = { cid: activeCompany ? activeCompany.id : 0 }
        dispatch(fetchDataRedux("fetch-hired-staff", obj, "FETCH_STAFF_LIST", false, false, true))
    }

    useEffect(() => {
        if (activeCompany) {
            fetchConv()
            fetchStaffs()
        }
    }, [activeCompany])

    return (
        <>
            <Header />
            <TitleBar title="Conversations" />
            <div className="site-wrapper">
                <div className="site-container">

                    <div className="outer-container">
                        <div className="titleBar">
                            <div className="titleBar-left">
                                <h2>All Conversations</h2>
                                <p>Search conversations with staffs</p>
                            </div>
                        </div>
                    </div>

                    <div className="department-list mt-2">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                            <Nav variant="n" className="navStyle-short">
                                <div className="navStyle-short-wrap">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Staff Conversation</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Project Conversation</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Embed Conversation</Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="department-list mt-5">
                                        <div className="row align-items-center mb-4">
                                            <div className="col-auto">
                                                <div className="select-style alt input-wrap mt-0">
                                                    <label className="label" htmlFor="">Staff</label>
                                                    <Select
                                                        options={staffs}
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        onChange={(e) => handleChange("typeS", e.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-auto" style={{ marginRight: 'auto' }}>
                                                <div className="input-wrap mt-0">
                                                    <label className="label" htmlFor="">Search</label>
                                                    <div className="inp-outer icon-left round">
                                                        <input
                                                            className="inp sm"
                                                            type="text"
                                                            placeholder="Search"
                                                            value={filterObj.qs}
                                                            onChange={(e) => handleChange("qs", e.target.value)}
                                                        />
                                                        <span className="inp-icon"><BiSearch /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                {+auth.user.isClientAccount === 1 ? null :

                                                    <button
                                                        className="theme-btn sm-w-100"
                                                        onClick={handleShow}
                                                        data-tooltip-id="my-single-tooltip"
                                                        data-tooltip-content={`Please hire a staff to create a conversation.`}
                                                        style={{ cursor: isActive.staff ? "" : "not-allowed", background: isActive.staff ? "" : "gray" }}
                                                        disabled={!isActive.staff}
                                                    >
                                                        <span><AiFillPlusCircle /> New Conversation</span>
                                                    </button>
                                                }
                                                {isActive.staff ? null :
                                                    <Tooltip id="my-single-tooltip" place="bottom-start" />
                                                }

                                            </div>
                                        </div>

                                        <div className="row">
                                            {staffConv.length > 0 ?
                                                staffConv.filter((curElem) => {
                                                    return curElem.name.toLowerCase().includes(filterObj.qs.toLowerCase())
                                                }).filter((curElem) => {
                                                    if (filterObj.typeS !== "") {
                                                        if (curElem.staff.length > 0) {
                                                            return curElem.staff.find(({ name }) => name === filterObj.typeS)
                                                        }
                                                    } else {
                                                        return curElem
                                                    }
                                                }).length > 0 ?
                                                    staffConv.filter((curElem) => {
                                                        return curElem.name.toLowerCase().includes(filterObj.qs.toLowerCase())
                                                    }).filter((curElem) => {
                                                        if (filterObj.typeS !== "") {
                                                            if (curElem.staff.length > 0) {
                                                                return curElem.staff.find(({ name }) => name === filterObj.typeS)
                                                            }
                                                        } else {
                                                            return curElem
                                                        }
                                                    }).
                                                        map((curElem, index) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    <SingleConv
                                                                        type="single"
                                                                        curElem={curElem}
                                                                        fetchConv={fetchConv}
                                                                    />
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    :
                                                    <div className="col-12 text-center mt-4">
                                                        No conversation found.
                                                    </div>
                                                :
                                                <div className="col-12 text-center mt-4">
                                                    {loader.fetch && activeCompany ?
                                                        <FaSpinner className="spin" size={25} />
                                                        : "You have no staff conversation created yet."}
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="department-list mt-5">

                                        <div className="row align-items-center mb-4">
                                            <div className="col-auto">
                                                <div className="select-style alt input-wrap mt-0">
                                                    <label className="label" htmlFor="">Staff</label>
                                                    <Select
                                                        options={staffs}
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        onChange={(e) => handleChange("typeP", e.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-auto" style={{ marginRight: 'auto' }}>
                                                <div className="input-wrap mt-0">
                                                    <label className="label" htmlFor="">Search</label>
                                                    <div className="inp-outer icon-left round">
                                                        <input
                                                            className="inp sm"
                                                            type="text"
                                                            placeholder="Search"
                                                            value={filterObj.qp}
                                                            onChange={(e) => handleChange("qp", e.target.value)}

                                                        />
                                                        <span className="inp-icon"><BiSearch /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                {+auth.user.isClientAccount === 1 ? null :

                                                    <button className="theme-btn sm-w-100"
                                                        onClick={handleShow2}
                                                        data-tooltip-id="my-proj-tooltip"
                                                        data-tooltip-content={`Please create a project to create a conversation.`}
                                                        style={{ cursor: proj.length > 0 ? "" : "not-allowed", background: proj.length > 0 ? "" : "gray" }}
                                                        disabled={proj.length > 0 ? false : true}
                                                    ><span><AiFillPlusCircle /> New Conversation</span></button>
                                                }
                                            </div>
                                            {proj.length > 0 ? null :
                                                <Tooltip id="my-proj-tooltip" place="bottom-start" />
                                            }
                                        </div>

                                        <div className="row">
                                            {projConv.length > 0 ?
                                                projConv.filter((curElem) => {
                                                    return curElem.name.toLowerCase().includes(filterObj.qp.toLowerCase())
                                                }).filter((curElem) => {
                                                    if (filterObj.typeP !== "") {
                                                        if (curElem.staff.length > 0) {
                                                            return curElem.staff.find(({ name }) => name === filterObj.typeP)
                                                        }
                                                    } else {
                                                        return curElem
                                                    }
                                                }).length > 0 ?
                                                    projConv.filter((curElem) => {
                                                        return curElem.name.toLowerCase().includes(filterObj.qp.toLowerCase())
                                                    }).filter((curElem) => {
                                                        if (filterObj.typeP !== "") {
                                                            if (curElem.staff.length > 0) {
                                                                return curElem.staff.find(({ name }) => name === filterObj.typeP)
                                                            }
                                                        } else {
                                                            return curElem
                                                        }
                                                    }).map((curElem, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <SingleConv
                                                                    type="group"
                                                                    curElem={curElem}
                                                                    fetchConv={fetchConv}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    :
                                                    <div className="col-12 text-center mt-4">
                                                        {loader.fetch && activeCompany ?
                                                            <FaSpinner className="spin" size={25} />
                                                            :
                                                            "No conversation found."}
                                                    </div>
                                                :
                                                <div className="col-12 text-center mt-4">
                                                    {loader.fetch && activeCompany ?
                                                        <FaSpinner className="spin" size={25} />
                                                        : "You have no project conversation created yet."}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className="department-list mt-5">
                                        <div className="row align-items-center mb-4">
                                            <div className="col-auto">
                                                <div className="select-style alt input-wrap mt-0">
                                                    <label className="label" htmlFor="">Staff</label>
                                                    <Select
                                                        options={staffs}
                                                        className="react-select-container"
                                                        classNamePrefix="react-select"
                                                        onChange={(e) => handleChange("typeE", e.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-auto" style={{ marginRight: 'auto' }}>
                                                <div className="input-wrap mt-0">
                                                    <label className="label" htmlFor="">Search</label>
                                                    <div className="inp-outer icon-left round">
                                                        <input
                                                            className="inp sm"
                                                            type="text"
                                                            placeholder="Search"
                                                            value={filterObj.qe}
                                                            onChange={(e) => handleChange("qe", e.target.value)}
                                                        />
                                                        <span className="inp-icon"><BiSearch /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {embedConv.length > 0 ?
                                                embedConv.filter((curElem) => {
                                                    return curElem.name.toLowerCase().includes(filterObj.qe.toLowerCase())
                                                }).filter((curElem) => {
                                                    if (filterObj.typeE !== "") {
                                                        if (curElem.staff.length > 0) {
                                                            return curElem.staff.find(({ name }) => name === filterObj.typeE)
                                                        }
                                                    } else {
                                                        return curElem
                                                    }
                                                }).map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <SingleConv
                                                                type="single"
                                                                curElem={curElem}
                                                                fetchConv={fetchConv}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })
                                                :
                                                <div className="col-12 text-center mt-4">
                                                    {loader.fetch && activeCompany ?
                                                        <FaSpinner className="spin" size={25} />
                                                        : "You have no embed conversation created yet."}
                                                </div>
                                            }


                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>
                    </div>
                </div>
            </div>

            <Footer />

            <CreateConvModal
                show={show}
                handleClose={handleClose}
                fetchConv={fetchConv}
            />

            <CreateProjConvModal
                show={show2}
                handleClose={handleClose2}
                fetchConv={fetchConv}
                setProj={setProj}
                proj={proj}

            />
        </>
    )
}

export default ChatNew;