import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onConnectInte } from '../../../../Redux/Actions/SocialActions'
import { setAlert } from '../../../../Redux/Actions/AlertActions'
import axios from 'axios'

const ConnectModal = ({ shortType, url, name, show, handleClose }) => {
    const FormDataJson = require('form-data-json-convert')
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        submit: false,
        fetch: false
    })
    const [eventIds, setEventIds] = useState([])
    const [state, setState] = useState({
        name: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { ...state }
        if (shortType === "AR") {
            delete data.apiKey
            let element = document.getElementById("auto-form")
            let form = element.getElementsByTagName("form")
            let formData = FormDataJson.toJson(document.querySelector("#auto-form"), { includeDisabled: true, includeButtonValues: true, arrayify: true })
            formData.postUrl = form[0].action
            data = {
                ...data,
                name: data.name,
                code: data,
            }
        }
        if (shortType === "twilio") {
            data.type = "twilio"
        }
        setLoader({
            ...loader,
            submit: true
        })

        dispatch(onConnectInte(url, data, handleClose, loader, setLoader))
    }

    const handleFetchEvents = async (e) => {
        if (state.apiKey) {
            let urlVal = `https://api.cal.com/v1/event-types?apiKey=${state.apiKey}`
            setLoader({
                ...loader,
                fetch: true
            })
            await axios({
                method: "GET",
                url: urlVal
            }).then((res) => {
                if (res.data.event_types.length > 0) {
                    setEventIds(res.data.event_types.map((curElem) => {
                        return {
                            id: curElem.id,
                            title: curElem.title
                        }
                    }))
                }
                setLoader({
                    ...loader,
                    fetch: false
                })
            }).catch((err) => {
                dispatch(setAlert("Please provide a valid API key.", "danger"))
                console.log(err)
                setLoader({
                    ...loader,
                    fetch: false
                })
            })
        } else {
            dispatch(setAlert("Please provide API Key to get events!", "danger"))
        }
    }


    return (
        <Modal className="VideoModal modal-700" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="titleBar">
                    <div className="titleBar-profile">
                        <div className="titleBar-left">
                            <h2>{name}</h2>
                        </div>
                    </div>
                    <span className="modalClose" onClick={handleClose}><AiOutlineClose /></span>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="input-wrap">
                        <label htmlFor="">Enter Name</label>
                        <div className="inp-outer">
                            <input
                                className="inp alt"
                                type="text"
                                name='name'
                                placeholder='Enter Name'
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    {shortType === "AR" ?
                        <div className="input-wrap">
                            <label htmlFor="">Opt-in Code</label>
                            <div className="inp-outer">
                                <textarea
                                    className="inp alt"
                                    type="text"
                                    name='code'
                                    rows={5}
                                    placeholder='Enter Code Here'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        :
                        shortType === "EL" ?
                            <>
                                <div className="input-wrap">
                                    <label htmlFor="">Enter API Key</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp alt"
                                            type="text"
                                            name='apiKey'
                                            placeholder='Enter API Key'
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                            </>

                            : shortType === "stripe" ?
                                <div className="input-wrap">
                                    <label htmlFor="">Secret Key</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp alt"
                                            type="text"
                                            name='secretKey'
                                            placeholder='Enter Secret Key'
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                :
                                shortType === "paypal" ?
                                    <>
                                        <div className="input-wrap">
                                            <label htmlFor="">Client Id</label>
                                            <div className="inp-outer">
                                                <input
                                                    className="inp alt"
                                                    type="text"
                                                    name='clientId'
                                                    placeholder='Enter Client ID'
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="input-wrap">
                                            <label htmlFor="">Secret Id</label>
                                            <div className="inp-outer">
                                                <input
                                                    className="inp alt"
                                                    type="text"
                                                    name='secretId'
                                                    placeholder='Enter Secret ID'
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    shortType === "twilio" ?
                                        <>
                                            <div className="input-wrap">
                                                <label htmlFor="">Account SID</label>
                                                <div className="inp-outer">
                                                    <input
                                                        className="inp alt"
                                                        type="text"
                                                        placeholder="77adaqe3asn a23xxxq3232342asdnsajoq23jo23ljasej213412"
                                                        name='accountSid'
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-wrap">
                                                <label htmlFor="">Auth Token</label>
                                                <div className="inp-outer">
                                                    <input
                                                        className="inp alt"
                                                        type="text"
                                                        placeholder="Enter Auth Token"
                                                        onChange={handleChange}
                                                        name='authToken'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-wrap">
                                                <label htmlFor="">From Number</label>
                                                <div className="inp-outer">
                                                    <input
                                                        className="inp alt"
                                                        type="text"
                                                        placeholder="Enter Twilio Number"
                                                        onChange={handleChange}
                                                        name='twilioNumber'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-wrap">
                                                <label htmlFor="">To Number (Need for testing)</label>
                                                <div className="inp-outer">
                                                    <input
                                                        className="inp alt"
                                                        type="text"
                                                        placeholder="Enter To Number"
                                                        onChange={handleChange}
                                                        name='toNumber'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </>

                                        : shortType === "smtp" ?
                                            <>
                                                <div className="input-wrap">
                                                    <label htmlFor="">Enter Host</label>
                                                    <div className="inp-outer">
                                                        <input
                                                            className="inp alt"
                                                            type="text"
                                                            placeholder="Enter Host"
                                                            name='host'
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-wrap">
                                                    <label htmlFor="">Enter Port</label>
                                                    <div className="inp-outer">
                                                        <input
                                                            className="inp alt"
                                                            type="number"
                                                            placeholder="Enter Port"
                                                            name='port'
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-wrap">
                                                    <label htmlFor="">Enter Username</label>
                                                    <div className="inp-outer">
                                                        <input
                                                            className="inp alt"
                                                            type="text"
                                                            placeholder="Enter Username"
                                                            onChange={handleChange}
                                                            name='username'
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-wrap">
                                                    <label htmlFor="">Enter Password</label>
                                                    <div className="inp-outer">
                                                        <input
                                                            className="inp alt"
                                                            type="password"
                                                            placeholder="Enter Password"
                                                            onChange={handleChange}
                                                            name='password'
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-wrap">
                                                    <label htmlFor="">Enter Email</label>
                                                    <div className="inp-outer">
                                                        <input
                                                            className="inp alt"
                                                            type="email"
                                                            placeholder="Enter Email"
                                                            onChange={handleChange}
                                                            name='from'
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-wrap">
                                                    <label htmlFor="">SSL</label>
                                                    <div className="inp-outer">
                                                        <select
                                                            className="inp alt"
                                                            onChange={handleChange}
                                                            name='ssl'
                                                            required
                                                        >
                                                            <option value="">Select Option</option>
                                                            <option value="ssl">Yes</option>
                                                            <option value="tls">No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                            : shortType === "CC" ?
                                                <>
                                                    <div className="input-wrap">
                                                        <label htmlFor="">Enter API Key</label>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className="inp-outer" style={{ width: "80%" }}>
                                                                <input
                                                                    className="inp alt"
                                                                    type="text"
                                                                    name='apiKey'
                                                                    placeholder='Enter API Key'
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                            <button className='theme-btn' type='button' onClick={handleFetchEvents}><span>Events {loader.fetch ? <FaSpinner className='spin' /> : ""}</span></button>
                                                        </div>
                                                    </div>
                                                    <div className="input-wrap">
                                                        <label htmlFor="">Event Type ID</label>
                                                        <div className="inp-outer" >
                                                            <select
                                                                className='inp alt'
                                                                name='eventId'
                                                                placeholder='Enter Event ID'
                                                                onChange={handleChange}
                                                                required
                                                            >
                                                                <option value={""}>Select Event ID</option>
                                                                {eventIds.length > 0 ?
                                                                    eventIds.map((curElem, index) => {
                                                                        return (
                                                                            <option key={index} value={curElem.id}>{curElem.title}</option>
                                                                        )
                                                                    }) : ""}

                                                            </select>
                                                        </div>
                                                    </div>


                                                </>

                                                : ""


                    }
                    <div className="input-wrap text-center">
                        <button className="theme-btn" disabled={loader.submit}><span>{loader.submit ? <>Connecting <FaSpinner className="spin" /></> : "Connect"}</span></button>
                    </div>
                </form>

                <div id='auto-form' style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: state.code }} />
            </Modal.Body>
        </Modal>
    )
}

export default ConnectModal