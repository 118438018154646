import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { BiSearch } from "react-icons/bi"
import { BsFillPlusCircleFill } from "react-icons/bs"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataRedux } from "../../../Redux/Actions/CommonActions";
import TitleBar from "../../CommonComponents/TitleBar";
import { FaSpinner } from "react-icons/fa";
import StaffRows from "../Staffs/StaffRows";
import Directory from "./Directory/Directory";
import { Tooltip } from "react-tooltip";


const YouAi = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const staff = useSelector(state => state.staff)
    const activeCompany = useSelector(state => state.company.activeCompany)
    const [pageType, setPageType] = useState("customAi")

    const [customStaff, setCustomStaff] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const [filterObj, setFilterObj] = useState({
        q: ""
    })

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleChange = (name, value) => {
        setFilterObj({
            ...filterObj,
            [name]: value
        })
    }

    const handleCreateButton = () => {
        if (activeCompany) {
            if (pageType === "customAi") {
                navigate("/create-custom-ai")
            } else {
                handleShow()
            }
        }
    }

    const fetchCustomStaffs = () => {
        const obj = { cid: activeCompany.id }
        dispatch(fetchDataRedux("fetch-hired-staff", obj, "FETCH_CUSTOMESTAFF_LIST", loader, setLoader))
    }

    useEffect(() => {
        let arr = []
        arr = staff.customList.filter((curElem) => {
            return curElem.type === "youAiStaff" && +curElem.companyId === +activeCompany.id
        })
        arr = arr.filter((curElem) => {
            return curElem.name.toLowerCase().includes(filterObj.q.toLowerCase())
        })
        setCustomStaff(arr)
    }, [staff.customList, filterObj])

    useEffect(() => {
        if (activeCompany) {
            fetchCustomStaffs()
        }
    }, [activeCompany])


    return (
        <>
            <TitleBar title="Custom Agent" />
            <Header />

            <div className="site-wrapper">
                <div className="site-container">
                    <div className="outer-container">
                        <div className="titleBar forStaff">
                            <div className="titleBar-left">
                                <h2>Custom Agent</h2>
                                <p>Create Custom Agent and Upload Directories</p>
                            </div>
                            <div className="titleBar-right">
                                <div className="input-wrap alt mt-0">
                                    <div className="inp-outer icon-left">
                                        <input
                                            className="inp"
                                            type="text"
                                            placeholder="Search"
                                            value={filterObj.q}
                                            onChange={(e) => handleChange("q", e.target.value)}
                                        />
                                        <span className="inp-icon"><BiSearch /></span>
                                    </div>
                                </div>
                                <div className="staffBtn">
                                    <button className={pageType === "customAi" ? "active" : ""} onClick={() => setPageType("customAi")}><span>Custom Agent</span></button>
                                    <button className={pageType === "dir" ? "active" : ""} onClick={() => setPageType("dir")}><span>Directory</span></button>
                                </div>
                                {+auth.user.isClientAccount === 1 ? null :
                                    <div className="create-btn">
                                        <div
                                            className="theme-btn sm-w-100"
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={`Please create a company to create a ${pageType === "dir" ? "directory" : "custom agent"}`}
                                            style={{ cursor: activeCompany ? "" : "not-allowed", background: activeCompany ? "" : "gray" }}
                                            onClick={handleCreateButton}>
                                            <span><BsFillPlusCircleFill /> Create</span>

                                        </div>
                                        {activeCompany ? null :
                                            <Tooltip id="my-tooltip" place="bottom-start" />
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="outer-container">
                        <div className="staff-list">
                            {pageType === "customAi" ?
                                <div className="row">
                                    {customStaff.length > 0 ?
                                        customStaff.length > 0 ?
                                            customStaff.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <StaffRows
                                                            curElem={curElem}
                                                            fetchStaffs={fetchCustomStaffs}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <div className="text-center mt-4">
                                                No custom staff available
                                            </div>
                                        :
                                        <div className="text-center mt-4">
                                            {
                                                loader.fetch && activeCompany ?
                                                    <FaSpinner className="spin" size={25} /> :
                                                    "No custom staff available"
                                            }
                                        </div>
                                    }
                                </div>
                                :
                                <Directory
                                    show={show}
                                    handleClose={handleClose}
                                    q={filterObj.q}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default YouAi;