import React, { useEffect, useState } from "react";
import CreateDirectoryModal from "./CreateDirectoryModal";
import { fetchDataRedux } from "../../../../Redux/Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import DirectoryRows from "./DirectoryRows";

const Directory = ({ show, handleClose, q }) => {

    const dispatch = useDispatch()
    const directory = useSelector(state => state.directory)
    const activeCompany = useSelector(state => state.company.activeCompany)

    const [dirShow, setDirShow] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchDirectoryList = () => {
        let data = { cid: activeCompany.id }
        dispatch(fetchDataRedux("fetch-directory-list", data, "FETCH_DIRECTORY_LIST", loader, setLoader, true))
    }

    useEffect(() => {
        setDirShow(directory.list)
    }, [directory])

    useEffect(() => {
        if (activeCompany) {
            fetchDirectoryList()
        }
    }, [activeCompany])
    return (
        <div className="row">
            {dirShow.length > 0 ?
                dirShow.filter((curElem) => {
                    return curElem.name.toLowerCase().includes(q.toLowerCase())
                }).map((curElem, index) => {
                    return (
                        <React.Fragment key={index}>
                            <DirectoryRows
                                curElem={curElem}
                            />
                        </React.Fragment>
                    )
                })
                :
                <div className="text-center mt-4">
                    {loader.fetch && activeCompany?
                        <FaSpinner className="spin" size={25} /> :
                        "No directory available"
                    }
                </div>
            }

            <CreateDirectoryModal
                show={show}
                handleClose={handleClose}
                activeCompany={activeCompany}
            />
        </div>
    )
}

export default Directory;